import React, {useEffect, useState} from 'react';
import './App.css';
import {UserContext} from './context/UserContext';
import {ActivePage} from "./utils/ActivePage";
import NavbarComponent from "./components/navigation/NavbarComponent";
import LoginScreen from "./components/pages/auth/LoginScreen";
import {UserDTO} from "./dto/UserDTO";
import {loadUserLocally, saveUserLocally} from "./services/storage.service";
import {ToastContainer} from 'react-toastify';
import fetchIntercept from 'fetch-intercept';
import DashboardPanel from "./components/pages/dashboard/DashboardPanel";
import AnalyticsPanel from "./components/pages/analytics/AnalyticsPanel";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {STRIPE_PUBLIC_KEY} from "./utils/config";
import {UserService} from "./services/user.service";

function App() {

  const [activePage, setActivePage] = useState<ActivePage>(ActivePage.DASHBOARD);
  const [user, setUser] = useState<UserDTO | null>(loadUserLocally);

  const fetchUser = async() => {
      const user = await UserService.getUser();

      if(user.code == 200) {
          setUser(user.data)
      }
  }

  useEffect(() => {
      saveUserLocally(user)
  }, [user]);

  useEffect(() => {
      if(user) {
          fetchUser()
      }
  }, [])

  fetchIntercept.register({
      request: function (url, config) {
          return [url, config]
      },

      requestError: function (error) {
          return Promise.reject(error);
      },

      response: function (response) {
          if(response.status == 403) {
              setUser(null);
          }
          return response;
      },

      responseError: function (error) {
          return Promise.reject(error);
      },
  });

  return (
      <UserContext.Provider value={{user, setUser}}>
          <ToastContainer/>
          <div className={"w-full h-full"}>
              {user === null ? <LoginScreen/> :
                  <>
                      <NavbarComponent activePage={activePage} setActivePage={setActivePage}/>
                      {activePage === ActivePage.DASHBOARD ? <DashboardPanel/> :
                          (activePage === ActivePage.ANALYTICS) ? <AnalyticsPanel/> : <></>}
                  </>
              }
          </div>
      </UserContext.Provider>
  );
}

export default App;
