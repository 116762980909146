import {getAccessToken, SIMLY_PARTNER_API_URL} from "../utils/config";
import {BasicResponse} from "./responses/basic.response";
import {ISearchCountryResponse} from "./responses/country.responses";
import {CountryAnalyticDTO, MonthlyAnalyticDTO, PlanAnalyticDTO, UserAnalyticDTO} from "../dto/AnalyticDTO";

export class AnalyticsService {

    public static getCountryAnalytics = async (): Promise<BasicResponse<CountryAnalyticDTO[]>> => {
        const resp = await fetch( SIMLY_PARTNER_API_URL + `/dashboard/analytics/country`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "x-simly-token": getAccessToken()!
            }
        });

        return await resp.json() as BasicResponse<CountryAnalyticDTO[]>;
    }

    public static getPlanAnalytics = async (): Promise<BasicResponse<PlanAnalyticDTO[]>> => {
        const resp = await fetch( SIMLY_PARTNER_API_URL + `/dashboard/analytics/plan`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "x-simly-token": getAccessToken()!
            }
        });

        return await resp.json() as BasicResponse<PlanAnalyticDTO[]>;
    }

    public static getUserAnalytics = async (): Promise<BasicResponse<UserAnalyticDTO[]>> => {
        const resp = await fetch( SIMLY_PARTNER_API_URL + `/dashboard/analytics/user`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "x-simly-token": getAccessToken()!
            }
        });

        return await resp.json() as BasicResponse<UserAnalyticDTO[]>;
    }

    public static getMonthlyAnalytics = async (): Promise<BasicResponse<MonthlyAnalyticDTO[]>> => {
        const resp = await fetch( SIMLY_PARTNER_API_URL + `/dashboard/analytics/month`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "x-simly-token": getAccessToken()!
            }
        });

        return await resp.json() as BasicResponse<MonthlyAnalyticDTO[]>;
    }
}
