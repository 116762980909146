import {getAccessToken, SIMLY_PARTNER_API_URL} from "../utils/config";
import {BasicResponse} from "./responses/basic.response";
import {ITransactionResponse} from "./responses/dashboard.responses";
import {PurchaseResponse, RefundResponse, UsageResponse} from "./responses/esim.responses";

export class ESIMsService {

    public static getUsage = async (esimId: string): Promise<BasicResponse<UsageResponse>> => {
        const resp = await fetch( SIMLY_PARTNER_API_URL + `/esims/usage/${esimId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "x-simly-token": getAccessToken()!
            }
        });

        return await resp.json() as BasicResponse<UsageResponse>;
    }

    public static purchase = async(planId: string, esimId?: string, email?: string): Promise<BasicResponse<PurchaseResponse>> => {
        const resp = await fetch( SIMLY_PARTNER_API_URL + `/esims/purchase`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "x-simly-token": getAccessToken()!
            },
            body: JSON.stringify({
                planId: planId,
                esimId: esimId,
                email: email,
            })
        });

        return await resp.json() as BasicResponse<PurchaseResponse>;
    }

    public static refund = async(transactionUUID: string): Promise<BasicResponse<RefundResponse>> => {
        const resp = await fetch( SIMLY_PARTNER_API_URL + `/esims/refund/${transactionUUID}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "x-simly-token": getAccessToken()!
            }
        });

        return await resp.json() as BasicResponse<RefundResponse>;
    }

}
