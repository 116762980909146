import React, {useCallback, useContext, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSheetPlastic, faWallet} from "@fortawesome/free-solid-svg-icons";
import {faClock} from "@fortawesome/free-regular-svg-icons";
import {TransactionService} from "../../../services/transaction.service";
import {toast} from "react-toastify";
import {UserContext} from "../../../context/UserContext";
import GraphComponent from "./graph/GraphComponent";
import {
    CountryAnalyticDTO,
    MonthlyAnalyticDTO,
    PlanAnalyticDTO,
    UserAnalyticDTO
} from "../../../dto/AnalyticDTO";
import {AnalyticsService} from "../../../services/analytics.service";
import {CircleFlag} from "react-circle-flags";
import {TabType} from "../dashboard/tab/AbstractTabTypes";
import {IDashboardSummary} from "../../../services/responses/dashboard.responses";

const AnalyticsPanel: React.FC<any> = ({}) => {

    const {user} = useContext(UserContext);

    const [loading, setLoading] = useState<boolean>(true);
    const [summaryData, setSummaryData] = useState<IDashboardSummary>();

    const [analyticsLoading, setAnalyticsLoading] = useState<boolean>(true);
    const [topCountries, setTopCountries] = useState<CountryAnalyticDTO[]>([]);
    const [topPackages, setTopPackages] = useState<PlanAnalyticDTO[]>([]);
    const [topUserCountries, setTopUserCountries] = useState<UserAnalyticDTO[]>([]);
    const [monthAnalytics, setMonthAnalytics] = useState<MonthlyAnalyticDTO[]>([]);

    const customId = "custom-simly-toast";

    const toastOptions = {
        position: toast.POSITION.TOP_CENTER,
        toastId: customId,
        autoClose: 3000,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: false,
        draggable: false
    }

    const fetchSummary = async () => {
        const data = await TransactionService.getDashboardSummary();

        setSummaryData(data.data);
        setLoading(false);
    }

    const fetchAnalytics = useCallback(async () => {
        await Promise.all([
            AnalyticsService.getCountryAnalytics().then(r => setTopCountries(r.data)),
            AnalyticsService.getPlanAnalytics().then(r => setTopPackages(r.data)),
            AnalyticsService.getUserAnalytics().then(r => setTopUserCountries(r.data)),
            AnalyticsService.getMonthlyAnalytics().then(r => setMonthAnalytics(r.data)),
        ])

        setAnalyticsLoading(false);
    }, [])


    useEffect(() => {
        fetchSummary().catch(() => toast.error('Unable to fetch the data from source.', toastOptions));
        fetchAnalytics().catch(() => toast.error('Unable to fetch the data from source.', toastOptions));
    }, [])

    return (
        <div className={"w-full h-full"}>
            <div className={"w-full h-full flex flex-col items-center justify-center mt-5"}>
                <div className={"w-11/12 h-16 sm:h-24 flex flex-row items-center justify-between"}>
                    <p className={"text-base font-bold sm:text-xl"}>Dashboard</p>
                    <button className={"w-24 h-8 sm:w-32 sm:h-10 flex flex-row items-center justify-center bg-blue-500 rounded-lg"}>
                        <p className={"text-white text-xs"}>Export Data</p>
                    </button>
                </div>
                <div className={"w-11/12 h-16 sm:h-24 flex flex-row items-center justify-between"}>
                    <p className={"text-[10px] font-bold"}>SUMMARY</p>
                    <select id="queryMonth" className="bg-white border border-gray-300 text-black w-32 sm:w-36 h-8 sm:h-10 rounded-lg text-sm flex items-center justify-center px-2 ">
                        <option value="current">This month</option>
                        <option value="pastMonth">Last month</option>
                        <option value="pastThree">Last 3 months</option>
                        <option value="pastSix">Last 6 months</option>
                        <option value="pastYear">Last year</option>
                    </select>
                </div>
                <div className={"w-11/12 h-64 sm:h-16 flex flex-col sm:flex-row items-center justify-between mt-5 sm:mt-0"}>
                    <div className={"w-9/12 sm:w-[32.5%] h-20 sm:h-24 flex flex-row border border-gray-200 rounded-xl items-center justify-center"}>
                        <div className={"w-10/12 xl:w-[95%] h-full flex flex-row items-center justify-start"}>
                            <div className={"w-8 h-8 xl:w-12 xl:h-12 rounded-lg flex flex-row items-center justify-center bg-gray-100"}>
                                <img alt={"wallet"} src={"icons/wallet.png"} className={"w-4 h-4"} />
                            </div>

                            <div className={"w-9/12 sm:w-11/12 h-9 xl:h-14 flex flex-col ml-4 xl:mt-2"}>
                                <p className={"text-xs text-[#828282] font-semibold"}>Wallet Balance</p>
                                <div className={"flex flex-row w-full justify-between items-center"}>
                                    {loading ? <div className={"animate-pulse h-8 sm:h-4 w-32 bg-gray-200 rounded mt-2 sm:mt:4"}></div> : <p className={"text-base xl:text-2xl font-bold"}>${user?.balance}</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"w-9/12 sm:w-[32.5%] h-16 sm:h-24 flex flex-row border border-gray-200 rounded-xl items-center justify-center"}>
                        <div className={"w-10/12 xl:w-[95%] h-full flex flex-row items-center justify-start"}>
                            <div className={"w-8 h-8 xl:w-12 xl:h-12 rounded-lg flex flex-row items-center justify-center bg-gray-100"}>
                                <FontAwesomeIcon className={"text-xs xl:text-base"} icon={faSheetPlastic} />
                            </div>

                            <div className={"w-16 h-9 xl:h-14 flex flex-col ml-4 xl:mt-2"}>
                                <p className={"text-xs text-gray-500 font-semibold"}>{user?.type == "Whitelabel" ? "Sales" : "Earned"}</p>
                                {loading ? <div className={"animate-pulse h-8 sm:h-4 w-32 bg-gray-200 rounded mt-2 sm:mt:4"}></div> : <p className={"text-base xl:text-2xl font-bold"}>${user?.type == "Whitelabel" ? summaryData?.sales : summaryData?.earned}</p>}
                            </div>
                        </div>
                    </div>
                    <div className={"w-9/12 sm:w-[32.5%] h-16 sm:h-24 flex flex-row border border-gray-200 rounded-xl items-center justify-center"}>
                        <div className={"w-10/12 xl:w-[95%] h-full flex flex-row items-center justify-start"}>
                            <div className={"w-8 h-8 xl:w-12 xl:h-12 rounded-lg flex flex-row items-center justify-center bg-gray-100"}>
                                <FontAwesomeIcon className={"text-xs xl:text-base"} icon={faClock} />
                            </div>

                            <div className={"w-full h-9 xl:h-14 flex flex-col ml-4 xl:mt-2"}>
                                <p className={"text-xs text-gray-500 font-semibold"}>Bundles Sold</p>
                                {loading ? <div className={"animate-pulse h-4 w-32 bg-gray-200 rounded mt-2 sm:mt:4"}></div> : <p className={"text-base xl:text-2xl font-bold"}>{summaryData?.bundles}</p>}
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"w-11/12 h-[62rem] sm:h-[24rem] flex flex-col sm:flex-row items-center justify-between mt-5 sm:mt-0"}>
                    <div className={"w-9/12 sm:w-[32.5%] h-[19.5rem] flex flex-col border border-gray-200 rounded-2xl items-center"}>
                        <div className={"sm:w-[90%] lg:w-[95%] h-7 flex flex-col justify-between mt-3"}>
                            <p className={"text-sm sm:text-xs lg:text-sm font-semibold text-gray-600"}>Top Countries</p>
                            <div className={"w-full h-[0.09rem] bg-gray-200"}></div>
                        </div>

                        <div className={"w-[95%] h-60 flex flex-col gap-2 mt-3"}>
                            {analyticsLoading ? (<>
                                {[...Array(6)].map(i =>
                                    <div key={i} className={"flex flex-row ml-2 w-full h-8 justify-start items-center"}>
                                        <div className={"animate-pulse bg-gray-200 rounded-full w-4 h-4 lg:w-6 lg:h-6"}/>
                                        <div className={"ml-3 animate-pulse bg-gray-200 w-[80%] lg:w-[90%] h-4 lg:h-6 rounded-xl"}></div>
                                    </div>
                                )} </>) : <>
                                {topCountries.map(country =>
                                    <div className={"flex flex-row ml-2 w-full h-8 justify-between items-center"}>
                                        <div className={"flex flex-row w-auto h-8 items-center justify-between"}>
                                            <img alt={country.country.name} src={`${country.country.countryImageURL}`} className={"w-4 h-4 lg:w-6 lg:h-6"}/>
                                            <p className={"text-sm w-28 lg:w-auto lg:text-base ml-2 lg:ml-3 text-gray-700 truncate"}>{country.country.name}</p>
                                        </div>

                                        <p className={"text-sm lg:text-base font-bold mr-4 lg:mr-2"}>{country.amount}</p>
                                    </div>
                                )}
                                </>}
                        </div>
                    </div>
                    <div className={"w-9/12 sm:w-[32.5%] h-[19.5rem] flex flex-col border border-gray-200 rounded-2xl items-center"}>
                        <div className={"sm:w-[90%] lg:w-[95%] h-7 flex flex-col justify-between mt-3"}>
                            <p className={"text-sm sm:text-xs lg:text-sm font-semibold text-gray-600"}>Top Packages</p>
                            <div className={"w-full h-[0.09rem] bg-gray-200"}></div>
                        </div>

                        <div className={"w-[95%] h-60 flex flex-col mt-3 gap-2"}>
                            {analyticsLoading ? (<>
                                {[...Array(6)].map(i =>
                                    <div key={i} className={"flex flex-row ml-2 w-full h-8 justify-start items-center"}>
                                        <div className={"animate-pulse bg-gray-200 rounded-full w-4 h-4 lg:w-6 lg:h-6"}/>
                                        <div className={"ml-3 animate-pulse bg-gray-200 w-[80%] lg:w-[90%] h-4 lg:h-6 rounded-xl"}></div>
                                    </div>
                                )} </>) : <>
                                {topPackages.map(pkg =>
                                    <div className={"flex flex-row ml-2 w-full h-8 justify-between items-center"}>
                                        <div className={"flex flex-row w-auto h-8 items-center justify-between"}>
                                            <img alt={pkg.country.name} src={`${pkg.country.countryImageURL}`} className={"w-4 h-4 lg:w-6 lg:h-6"}/>
                                            <p className={"text-sm w-28 lg:w-auto lg:text-base ml-2 lg:ml-3 text-gray-700 truncate"}>{pkg.plan.name}</p>
                                        </div>

                                        <p className={"text-sm lg:text-base font-bold mr-4 lg:mr-2"}>{pkg.amount}</p>
                                    </div>
                                )}
                            </>}
                        </div>
                    </div>
                    <div className={"w-9/12 sm:w-[32.5%] h-[19.5rem] flex flex-col border border-gray-200 rounded-2xl items-center"}>
                        <div className={"sm:w-[90%] lg:w-[95%] h-7 flex flex-col justify-between mt-3"}>
                            <p className={"text-sm sm:text-xs lg:text-sm font-semibold text-gray-600"}>Top User Countries</p>
                            <div className={"w-full h-[0.09rem] bg-gray-200"}></div>
                        </div>

                        <div className={"w-[95%] h-60 flex flex-col gap-2 mt-3"}>
                            {analyticsLoading ? (<>
                                {[...Array(6)].map(i =>
                                    <div key={i} className={"flex flex-row ml-2 w-full h-8 justify-start items-center"}>
                                        <div className={"animate-pulse bg-gray-200 rounded-full w-4 h-4 lg:w-6 lg:h-6"}/>
                                        <div className={"ml-3 animate-pulse bg-gray-200 w-[80%] lg:w-[90%] h-4 lg:h-6 rounded-xl"}></div>
                                    </div>
                                )} </>) : <>
                                {topUserCountries.map(uC =>
                                    <div className={"flex flex-row ml-2 w-full h-8 justify-between items-center"}>
                                        <div className={"flex flex-row w-auto h-8 items-center justify-between"}>
                                            <CircleFlag countryCode={`${uC.country.isoCode.toLowerCase()}`} className={"w-4 h-4 lg:w-6 lg:h-6"}/>
                                            <p className={"text-sm w-28 lg:w-auto lg:text-base ml-2 lg:ml-3 text-gray-700 truncate"}>{uC.country.isoCode}</p>
                                        </div>

                                        <p className={"text-sm lg:text-base font-bold mr-4 lg:mr-2"}>{uC.amount}</p>
                                    </div>
                                )}
                            </>}
                        </div>
                    </div>
                </div>

                <GraphComponent loading={analyticsLoading} data={monthAnalytics}/>
            </div>

        </div>
    )
}

export default AnalyticsPanel;
