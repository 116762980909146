export enum SimStatus {
    PENDING = "PENDING",
    PENDING_INSTALLATION = "PENDING_INSTALLATION",
    PENDING_ACTIVATION = "PENDING_ACTIVATION",
    ACTIVE = "ACTIVE",
    DELETED = "TERMINATED",
}

export const getSimStatusColorSandwich = (simStatus: SimStatus, prefix: string, suffix: string) => {
    return prefix + getSimStatusColor(simStatus) + suffix;
}

export const formatSimStatus = (status: SimStatus): string => {
    return status
        .toLowerCase()
        .replace(/_/g, ' ')
        .replace(/\b\w/g, (match) => match.toUpperCase());
}

export const getSimStatusColor = (simStatus: SimStatus): string => {
    switch (simStatus) {
        case SimStatus.ACTIVE:
            return "#33C759"
        case SimStatus.PENDING:
            return "#F1A239"
        case SimStatus.DELETED:
            return "#FC4C02"
        case SimStatus.PENDING_INSTALLATION:
            return "#D6D6D6"
        case SimStatus.PENDING_ACTIVATION:
            return "#F1A239"
    }
}
