import React from "react";
import {ActivePage} from "../../utils/ActivePage";
import {toast} from "react-toastify";

type NavigationPillProp = {
    pillPage: ActivePage,
    activePage: ActivePage,
    setActivePage: (page: ActivePage) => void
};

const NavigationPill: React.FC<any> = ({ pillPage, activePage, setActivePage }: NavigationPillProp) => {

    const customId = "custom-simly-toast";

    const toastOptions = {
        position: toast.POSITION.TOP_CENTER,
        toastId: customId,
        autoClose: 3000,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: false,
        draggable: false
    }

    return (
        <div className={`w-[5.5rem] h-8 sm:w-24 sm:h-9 flex flex-row items-center justify-center bg-gray-200 rounded-2xl cursor-pointer text-center ${pillPage === activePage ? 'bg-[#F7F7F7]' : 'bg-white'}`}
            onClick={() => {
                if(pillPage == ActivePage.SETTINGS) {
                    toast.info('That page is still being worked on, check back later!', toastOptions)
                    return;
                }

                setActivePage(pillPage)
            }}>
            <p className={`text-xs sm:text-sm ${pillPage === activePage ? 'font-bold' : 'font-semibold text-gray-400'}`}>{pillPage}</p>
        </div>
    );

}

export default NavigationPill;
