import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSheetPlastic, faWallet} from "@fortawesome/free-solid-svg-icons";
import {faClock} from "@fortawesome/free-regular-svg-icons";
import TransactionTableComponent from "./table/TransactionTableComponent";
import {TransactionService} from "../../../services/transaction.service";
import {toast} from "react-toastify";
import {UserContext} from "../../../context/UserContext";
import {TabType} from "./tab/AbstractTabTypes";
import AbstractTabComponent from "./tab/AbstractTabComponent";
import {IDashboardSummary, ITransactionObjectResponse} from "../../../services/responses/dashboard.responses";
import {UserDTO} from "../../../dto/UserDTO";
import {UserService} from "../../../services/user.service";

const DashboardPanel: React.FC<any> = ({}) => {

    const {user, setUser} = useContext(UserContext);
    const [loading, setLoading] = useState<boolean>(true);
    const [summaryData, setSummaryData] = useState<IDashboardSummary>();
    const [isTabOpen, setTabOpen] = useState<boolean>(false);
    const [tabType, setTabType] = useState<TabType>(TabType.GENERATE);
    const [search, setSearch] = useState<string>("");
    const [selectedTransaction, setSelectedTransaction] = useState<ITransactionObjectResponse | undefined>(undefined);
    const [latestPurchaseId, setLatestPurchaseId] = useState<string | undefined>(undefined);

    const customId = "custom-simly-toast";

    const toastOptions = {
        position: toast.POSITION.TOP_CENTER,
        toastId: customId,
        autoClose: 3000,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: false,
        draggable: false
    }

    const fetchSummary = async () => {
        const data = await TransactionService.getDashboardSummary();

        setSummaryData(data.data);
        setLoading(false);
    }

    const openTab = (type: TabType) => {
        setTabType(type);
        setTabOpen(true);
    }

    const openTransaction = (transaction: ITransactionObjectResponse) => {
        openTab(TabType.ESIM_DETAILS);
        setSelectedTransaction(transaction);
    }

    useEffect(() => {
        fetchSummary().catch(() => toast.error('Unable to fetch the data from source.', toastOptions));
    }, [])

    useEffect(() => {
        fetchSummary().catch(() => toast.error('Unable to fetch the data from source.', toastOptions));
    }, [latestPurchaseId])

    useEffect(() => {
        if (isTabOpen) {
            document.body.classList.add("overflow-hidden");
        } else {
            document.body.classList.remove("overflow-hidden");
        }
    }, [isTabOpen])

    return (
        <div className={`w-full h-full overflow-hidden`}>
            <div className={`w-full h-full flex flex-col items-center justify-center mt-5`}>
                <div className={"w-11/12 h-16 sm:h-24 flex flex-row items-center justify-between"}>
                    <p className={"text-base font-bold sm:text-xl text-[#333333]"}>Dashboard</p>
                    <button className={"w-24 h-8 sm:w-32 sm:h-10 flex flex-row items-center justify-center bg-blue-500 rounded-lg"}>
                            <p className={"text-white text-xs"} onClick={() =>
                                openTab(TabType.GENERATE)
                            }>Generate eSIM</p>
                    </button>
                </div>
                <div className={"w-11/12 h-12 flex flex-row items-center justify-start"}>
                    <div className={"w-full sm:w-2/3 lg:w-1/3 h-12 border-[3px] border-[#F7F7F7] rounded-xl flex flex-row items-center"}>
                        <img alt={"search"} src={"icons/search-outline.png"} className={"ml-6 w-6 h-6"}/>
                        <input type="text" value={search}
                               onChange={(e) => setSearch(e.target.value)}
                               className="w-full focus:outline-none pl-5 text-[#828282]"
                               placeholder="Search by email, plan, UID and more..." aria-label="Search text"/>
                    </div>
                </div>
                <div className={"w-11/12 h-16 sm:h-24 flex flex-row items-center justify-between"}>
                    <p className={"text-[10px] font-bold"}>SUMMARY</p>
                    <select id="queryMonth" className="bg-white border border-gray-300 text-black w-32 sm:w-36 h-8 sm:h-10 rounded-lg text-sm flex items-center justify-center px-2 ">
                        <option value="current">This month</option>
                        <option value="pastMonth">Last month</option>
                        <option value="pastThree">Last 3 months</option>
                        <option value="pastSix">Last 6 months</option>
                        <option value="pastYear">Last year</option>
                    </select>
                </div>
                <div className={"w-11/12 h-64 sm:h-16 flex flex-col sm:flex-row items-center justify-between mt-5 sm:mt-0"}>
                    <div className={"w-9/12 sm:w-[32.5%] h-20 sm:h-24 flex flex-row border border-gray-200 rounded-xl items-center justify-center"}>
                        <div className={"w-10/12 xl:w-[95%] h-full flex flex-row items-center justify-start"}>
                            <div className={"w-8 h-8 xl:w-12 xl:h-12 rounded-lg flex flex-row items-center justify-center bg-gray-100"}>
                                <img alt={"wallet"} src={"icons/wallet.png"} className={"w-4 h-4"} />
                            </div>

                            <div className={"w-9/12 sm:w-11/12 h-9 xl:h-14 flex flex-col ml-4 xl:mt-2"}>
                                <p className={"text-xs text-[#828282] font-semibold"}>Wallet Balance</p>
                                <div className={"flex flex-row w-full justify-between items-center"}>
                                    {loading ? <div className={"animate-pulse h-8 sm:h-4 w-32 bg-gray-200 rounded mt-2 sm:mt:4"}></div> : <p className={"text-base xl:text-2xl font-bold"}>${user?.balance}</p>}
                                    <div className={"h-7 sm:h-9 rounded-full bg-[#04C500] bg-opacity-10 flex items-center justify-center cursor-pointer"} onClick={() => {
                                        openTab(TabType.TOPUP)
                                    }}>
                                        <p className={"text-[#359A73] font-bold text-xs sm:text-sm pr-4 pl-4"}>Add Money</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"w-9/12 sm:w-[32.5%] h-16 sm:h-24 flex flex-row border border-gray-200 rounded-xl items-center justify-center"}>
                        <div className={"w-10/12 xl:w-[95%] h-full flex flex-row items-center justify-start"}>
                            <div className={"w-8 h-8 xl:w-12 xl:h-12 rounded-lg flex flex-row items-center justify-center bg-gray-100"}>
                                <FontAwesomeIcon className={"text-xs xl:text-base"} icon={faSheetPlastic} />
                            </div>

                            <div className={"w-16 h-9 xl:h-14 flex flex-col ml-4 xl:mt-2"}>
                                <p className={"text-xs text-gray-500 font-semibold"}>{user?.type == "Whitelabel" ? "Sales" : "Earned"}</p>
                                {loading ? <div className={"animate-pulse h-8 sm:h-4 w-32 bg-gray-200 rounded mt-2 sm:mt:4"}></div> : <p className={"text-base xl:text-2xl font-bold"}>${user?.type == "Whitelabel" ? summaryData?.sales : summaryData?.earned}</p>}
                            </div>
                        </div>
                    </div>
                    <div className={"w-9/12 sm:w-[32.5%] h-16 sm:h-24 flex flex-row border border-gray-200 rounded-xl items-center justify-center"}>
                        <div className={"w-10/12 xl:w-[95%] h-full flex flex-row items-center justify-start"}>
                            <div className={"w-8 h-8 xl:w-12 xl:h-12 rounded-lg flex flex-row items-center justify-center bg-gray-100"}>
                                <FontAwesomeIcon className={"text-xs xl:text-base"} icon={faClock} />
                            </div>

                            <div className={"w-full h-9 xl:h-14 flex flex-col ml-4 xl:mt-2"}>
                                <p className={"text-xs text-gray-500 font-semibold"}>Bundles Sold</p>
                                {loading ? <div className={"animate-pulse h-4 w-32 bg-gray-200 rounded mt-2 sm:mt:4"}></div> : <p className={"text-base xl:text-2xl font-bold"}>{summaryData?.bundles}</p>}
                            </div>
                        </div>
                    </div>
                </div>
                <TransactionTableComponent openTransaction={openTransaction} search={search} latestPurchaseId={latestPurchaseId}/>
            </div>
            <AbstractTabComponent isTabOpen={isTabOpen} setTabOpen={setTabOpen} tabType={tabType} setTabType={setTabType} transaction={selectedTransaction} setLatestPurchaseId={setLatestPurchaseId}/>
            <div className={`fixed z-20 inset-0 bg-gray-500/50 ${isTabOpen ? '' : 'hidden'}`}></div>
        </div>
    )
}

export default DashboardPanel;
