import React, {useState} from "react";
import Spinner from "../../../../../utils/Spinner";
import {confirmAlert} from "react-confirm-alert";
import {UserDTO} from "../../../../../dto/UserDTO";
import {UserService} from "../../../../../services/user.service";
import JSZip from 'jszip';
import {SelectionOption} from "../../tab/types/GenerateTabComponent";
import {ESIMsService} from "../../../../../services/esims.service";
import {BasicResponse} from "../../../../../services/responses/basic.response";
import {PurchaseResponse} from "../../../../../services/responses/esim.responses";
import {IBasicCountryObject} from "../../../../../services/responses/objects/ICountryObject";

type PurchaseConfirmationAlertProp = {
    planId: SelectionOption;
    esimId?: string;
    email?: string;
    country: IBasicCountryObject;
    quantity: number;
    onClose: () => void;
    closeTab: () => void;
    setTabOpen: (b: boolean) => void;
    user: UserDTO | null;
    setUser: (user: UserDTO | null) => void;
    setLatestPurchaseId: (t: string) => void;
};

const PurchaseConfirmationAlert: React.FC<PurchaseConfirmationAlertProp> = ({ email, planId, esimId, country, quantity, onClose, closeTab, setTabOpen, user, setUser, setLatestPurchaseId }: PurchaseConfirmationAlertProp) => {
    const [isLoading, setLoading] = useState<boolean>(false);

    const buyPackage = async () => {
        const fetchPromises = [];

        for (let i = 1; i <= quantity; i++) {
            const delay = i > 1 ? 500 : 0;

            fetchPromises.push(
                new Promise(async (resolve, reject) => {
                    await new Promise((delayResolve) => setTimeout(delayResolve, delay));
                    try {
                        const result = await ESIMsService.purchase(
                            planId!.value, esimId, email && email.length > 0 ? email : undefined);
                        resolve(result);
                    } catch (error) {
                        console.error(`Error during fetch iteration ${i}: ${error}`);
                        reject(error);
                    }
                })
            );
        }

        const settledPromises = await Promise.allSettled(fetchPromises);
        const results = settledPromises.filter((result) => result.status === 'fulfilled')
            .map((result) => {
                const fulfilledResult = result as PromiseFulfilledResult<BasicResponse<PurchaseResponse>>;
                return fulfilledResult.value;
            });

        const qrCodeImageUrls = results.map((data) => ({
            esimId: data.data.id,
            image: data.data.qrCodeImageUrl
        }));

        const zip = new JSZip();

        const fetchAndConvertPromises = [];

        for (const qrCodeImage of qrCodeImageUrls) {
            fetchAndConvertPromises.push(fetchImageAndConvertToBlob(qrCodeImage.image));
        }

        try {
            const blobs = await Promise.all(fetchAndConvertPromises);

            for (let i = 0; i < blobs.length; i++) {
                const qrCodeImage = qrCodeImageUrls[i];
                zip.file(qrCodeImage.esimId + '-qr.png', blobs[i]);
            }
        } catch (error) {
            console.error("An error occurred while fetching and converting images: " + error);
        }

        zip.generateAsync({ type: 'blob' })
            .then((zipBlob) => {
                const url = window.URL.createObjectURL(zipBlob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = `qrcodes-b${Date.now()}.zip`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            });

        onClose()
        closeTab()

        setLatestPurchaseId(Date.now().toString());

        if(user) {
            setUser({
                ...user,
                balance: +Number(user.balance - planId.price * quantity).toFixed(3)
            })
        }

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div
                        className="relative px-4 min-h-screen md:flex md:items-center md:justify-center">
                        <div
                            className="bg-white rounded-lg md:max-w-md md:mx-auto p-4 fixed inset-x-0 bottom-0 z-50 mb-4 mx-4 md:relative">
                            <div className="md:flex items-center">
                                <img src={country.countryImageURL} className={"rounded-full border border-gray-300 flex items-center justify-center w-16 h-16 flex-shrink-0 mx-auto"} alt={"iso"}/>
                                <div className="mt-4 md:mt-0 md:ml-6 text-center md:text-left">
                                    <p className="font-bold">Successful transaction!</p>
                                    <p className="text-sm text-gray-700 mt-1">The user has been sent the eSIM. Thank you for dealing with Simly!</p>
                                </div>
                            </div>
                            <div className="text-center md:text-right mt-4 md:flex md:justify-end">
                                <button onClick={() => {
                                    onClose()
                                    setTabOpen(true)
                                }}
                                        className="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-green-200 text-green-700 rounded-lg font-semibold text-sm md:ml-2 md:order-2">Buy more?
                                </button>
                                <button className="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-gray-200 rounded-lg font-semibold text-sm mt-4 md:mt-0 md:order-1" onClick={onClose}>Close
                                </button>
                            </div>
                        </div>
                    </div>
                );
            }
        });
    }

    const fetchImageAndConvertToBlob = async (imageUrl: string): Promise<Blob> => {
        const response = await fetch(imageUrl);
        return await response.blob();
    };


    return (
        <div
            className="relative px-4 min-h-screen md:flex md:items-center md:justify-center">

            <div
                className="bg-white rounded-lg md:max-w-md md:mx-auto p-4 fixed inset-x-0 bottom-0 z-50 mb-4 mx-4 md:relative">
                <div className="md:flex items-center">
                    <img alt={"iso"} src={country.countryImageURL} className={"rounded-full border border-gray-300 flex items-center justify-center w-16 h-16 flex-shrink-0 mx-auto"}/>
                    <div className="mt-4 md:mt-0 md:ml-6 text-center md:text-left">
                        <p className="font-bold">Buy {quantity}x{planId?.label}?</p>
                        <p className="text-sm text-gray-700 mt-1">Are you sure you would like to buy <span className={"font-bold"}> {quantity}x{planId?.label} </span> {country.name} package for <span className={"font-bold"}>{planId.price * quantity}$</span>?
                        </p>
                    </div>
                </div>
                <div className="text-center md:text-right mt-4 md:flex md:justify-end">
                    <button disabled={isLoading} onClick={() => {
                        setLoading(true)
                        buyPackage()
                    }}
                            className="w-full md:w-32 px-4 py-3 bg-green-200 text-green-700 rounded-lg font-semibold text-sm md:ml-2 md:order-2 flex flex-row items-center justify-center">
                        {isLoading ? <Spinner/> : "Buy package"}
                    </button>
                    <button className="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-gray-200 rounded-lg font-semibold text-sm mt-4 md:mt-0 md:order-1" onClick={onClose}>Cancel
                    </button>
                </div>
            </div>
        </div>
    );
}

export default PurchaseConfirmationAlert;
