import {BasicResponse} from "./responses/basic.response";
import {UsageResponse} from "./responses/esim.responses";
import {getAccessToken, SIMLY_PARTNER_API_URL} from "../utils/config";
import {CountryResponse, ISearchCountryResponse} from "./responses/country.responses";

export class CountryService {

    public static searchCountries = async (search: string): Promise<BasicResponse<ISearchCountryResponse>> => {
        const resp = await fetch( SIMLY_PARTNER_API_URL + `/countries/?search=${search}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "x-simly-token": getAccessToken()!
            }
        });

        return await resp.json() as BasicResponse<ISearchCountryResponse>;
    }

    public static getCountryByISO = async (iso: string): Promise<BasicResponse<CountryResponse>> => {
        const resp = await fetch( SIMLY_PARTNER_API_URL + `/countries/${iso}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "x-simly-token": getAccessToken()!
            }
        });

        return await resp.json() as BasicResponse<CountryResponse>;
    }
}
