import {BasicResponse} from "./responses/basic.response";
import {UsageResponse} from "./responses/esim.responses";
import {getAccessToken, SIMLY_PARTNER_API_URL} from "../utils/config";
import {CardResponse} from "./responses/dashboard.responses";
import {TopUpOptions} from "../dto/UserDTO";

export class BalanceService {

    public static getCard = async (): Promise<BasicResponse<CardResponse>> => {
        const resp = await fetch(SIMLY_PARTNER_API_URL + `/dashboard/card`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "x-simly-token": getAccessToken()!
            },
        });

        return await resp.json() as BasicResponse<CardResponse>;
    }

    public static updateCard = async (cardToken: string): Promise<BasicResponse<CardResponse>> => {
        const resp = await fetch(SIMLY_PARTNER_API_URL + `/dashboard/card`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "x-simly-token": getAccessToken()!
            },
            body: JSON.stringify({
                cardToken: cardToken
            })
        });

        return await resp.json() as BasicResponse<CardResponse>;
    }

    public static topupAmount = async (amount: number): Promise<BasicResponse<string>> => {
        const resp = await fetch(SIMLY_PARTNER_API_URL + `/dashboard/topup`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "x-simly-token": getAccessToken()!
            },
            body: JSON.stringify({
                amount: amount
            })
        });

        return await resp.json() as BasicResponse<string>;
    }

    public static updateTopUpOptions = async (options: TopUpOptions): Promise<BasicResponse<TopUpOptions>> => {
        const resp = await fetch(SIMLY_PARTNER_API_URL + `/dashboard/topup/options`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "x-simly-token": getAccessToken()!
            },
            body: JSON.stringify(options)
        });

        return await resp.json() as BasicResponse<TopUpOptions>;
    }
}
