/** @jsxImportSource @emotion/react */

import React, {useEffect, useState} from "react";

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';

import { css } from '@emotion/react'
import tw from "twin.macro";
import {MonthlyAnalyticDTO} from "../../../../dto/AnalyticDTO";

type GraphProp = {
    loading: boolean,
    data: MonthlyAnalyticDTO[]
};

const GraphComponent: React.FC<any> = ({loading, data} : GraphProp) => {

    const [max, setMax] = useState<number>(0);

    useEffect(() => {
        if(data.length == 0)
            return;

        setMax(Math.max(...data.map(m => Number(m.amount))))
    }, [data])

    const range = function(max: number) {
        const result = []

        for (let i = max; i > 0; i -= max / 4) {
            result.push(i);
        }

        return result;
    }

    const style = {
        background: "linear-gradient(356.64deg, rgba(27, 89, 248, 0.8) 48.49%, rgba(27, 89, 248, 0) 282.14%)"
    }

    return (
        <div className={"w-11/12 h-[48rem] sm:h-[24rem] flex flex-col border border-gray-200 rounded-2xl items-center mb-10 mt-10 sm:mt-0"}>
            <div className={"sm:w-[95%] lg:w-[97%] h-7 flex flex-col justify-between mt-3"}>
                <p className={"sm:text-xs lg:text-sm font-semibold text-black"}>Overall Sales</p>
                <div className={"w-full h-[0.09rem] bg-gray-200"}></div>
            </div>

            <div className={"flex flex-col sm:flex-row w-[92%] lg:w-[95%] mt-5 justify-between lg:justify-start"}>
                <div className={`flex flex-row-reverse sm:flex-col h-10 w-full sm:w-[3%] lg:w-[6%] sm:h-64 sm:ml-0`}>
                    <div className={"flex flex-row-reverse justify-between w-[84%] sm:flex-col sm:h-64"}>
                        {loading ?
                            ([...Array(5)].map(i =>
                                <div key={i} className={"animate-pulse bg-gray-200 rounded-full w-8 h-7 sm:w-10 sm:h-6 lg:w-16 sm:justify-end"}>
                                </div>)) :
                            <>
                                {range(max).map(num =>
                                    <div className={"flex w-8 sm:justify-end"}>
                                        <p className={"text-sm lg:text-base"}>{num.toFixed(0)}</p>
                                    </div>
                                )}

                                <div className={"flex w-8 sm:justify-end"}>
                                    <p className={"text-sm lg:text-base"}>0</p>
                                </div>
                            </>
                        }
                    </div>

                </div>

                <div className={"flex flex-col sm:flex-row sm:w-[90%] w-full lg:w-11/12 sm:h-[19rem] h-[40rem] justify-between"}>

                    {loading ?
                        ([...Array(12)].map(i =>
                            <div key={i} className={"flex flex-row-reverse h-9 w-full sm:flex-col sm:w-auto sm:h-full justify-between items-center"}>
                                <div className={"relative h-9 w-[85%] sm:w-9 sm:h-64"}>
                                    <div className={"absolute bottom-0 h-9 w-full sm:w-9 sm:h-full animate-pulse bg-gray-200 rounded-full -z-10"}></div>
                                </div>

                                <div className={"animate-pulse bg-gray-200 w-9 h-8 rounded-xl"}/>
                            </div>
                        )) :

                        <>
                            {data.map(month =>
                                <div className={"flex flex-row-reverse h-9 w-full sm:flex-col sm:w-auto sm:h-full justify-between items-center"}>
                                    <div className={"relative h-9 w-[85%] sm:w-9 sm:h-64"}>
                                        {month.amount == 0 ?
                                            <Tippy className={"hidden sm:block"} content="No data available" arrow={true} placement={"right"} animation={"scale"} theme={"material"}>
                                                <Tippy className={"block sm:hidden"} content="No data available" arrow={true} placement={"bottom"} animation={"scale"} theme={"material"}>
                                                    <div className={"absolute bottom-0 h-9 w-full sm:w-9 sm:h-full bg-[#F2F7FF] rounded-full"}/>
                                                </Tippy>
                                            </Tippy>

                                            :
                                            <div className={`absolute bottom-0 h-9 w-full sm:w-9 sm:h-full bg-[#F2F7FF] rounded-full -z-10`}/>
                                        }


                                        <Tippy className={"hidden sm:block"} content={`$${month.amount}`} arrow={true} placement={"right"} animation={"scale"} theme={"material"}>
                                            <Tippy className={"block sm:hidden"} content={`$${month.amount}`} arrow={true} placement={"bottom"} animation={"scale"} theme={"material"}>
                                                <div
                                                    style={style}
                                                    css={css`
                                                            ${tw`absolute bottom-0 rounded-full z-10`};
                                                            @media (min-width: 640px) {
                                                              width: 100%;
                                                              height: ${((Number(month.amount) / max) * 100).toFixed(0)}%;
                                                            }
                                                            
                                                            @media (max-width: 640px) {
                                                              height: 100%;
                                                              width: ${((Number(month.amount) / max) * 100).toFixed(0)}%;
                                                            }
                                                            
                                                          `}
                                                />
                                            </Tippy>
                                        </Tippy>

                                    </div>

                                    <div className={"flex items-center justify-center"}>
                                        <p className={"text-xs sm:text-sm lg:text-base"}>{month.month}</p>
                                    </div>
                                </div>
                            )}

                        </>
                    }

                </div>
            </div>
        </div>
    )
}

export default GraphComponent;
