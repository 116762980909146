import {PlanStatus} from "./enums/plan.status";
import {SimStatus} from "./enums/esim.status";

export interface UsageResponse {
    sim: {
        status: SimStatus,
        size: number,
        consumed: number,
        consumedPercentage: number,
        topup: boolean
    },
    plan: {
        status: PlanStatus,
        daysLeft: number
    }
}

export interface PurchaseResponse {
    id: string,
    smdp: string,
    matchingID: string,
    qrCodeImageUrl: string,
    qrCodeString: string,
    dateCreated: string,
    provider: string,
    topups: string[],
    transaction: {
        uuid: string,
        processed: boolean,
        initial_price: number,
        paid_amount: number,
        dateCreated: string
    },
    plan: string,
    allowedPlans: string[]
}

export enum RefundStatus {
    REFUNDED = "REFUNDED",
    NOT_AVAILABLE = "NOT_AVAILABLE",
    NOT_ELIGIBLE = "NOT_ELIGIBLE",
    ERRORED = "ERRORED"
}

export interface RefundResponse {
    status: RefundStatus;
}
