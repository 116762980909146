import {getAccessToken, SIMLY_PARTNER_API_URL} from "../utils/config";
import {IDashboardSummary, ITransactionResponse} from "./responses/dashboard.responses";
import {BasicResponse} from "./responses/basic.response";

export class TransactionService {

    public static getDashboardSummary = async (): Promise<BasicResponse<IDashboardSummary>> => {
        const resp = await fetch( SIMLY_PARTNER_API_URL + "/dashboard/summary", {
            method: "GET",
            headers: {
                'x-simly-token': getAccessToken()!,
                'Content-Type': 'application/json'
            },
        });

        return await resp.json() as BasicResponse<IDashboardSummary>;
    }

    public static getPageTransactions = async (search: string, page: number, size: number): Promise<BasicResponse<ITransactionResponse>> => {
        const resp = await fetch( SIMLY_PARTNER_API_URL + `/dashboard/transactions?search=${search}&page=${page}&size=${size}`, {
            method: "GET",
            headers: {
                'x-simly-token': getAccessToken()!,
                'Content-Type': 'application/json'
            }
        });

        return await resp.json() as BasicResponse<ITransactionResponse>;
    }

}
