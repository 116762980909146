import React, {useContext, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faX} from "@fortawesome/free-solid-svg-icons";
import {TabType} from "./AbstractTabTypes";
import GenerateTabComponent from "./types/GenerateTabComponent";
import TopupBalanceTabComponent from "./types/TopupBalanceTabComponent";
import EsimTabComponent from "./types/EsimTabComponent";
import TopupEsimTabComponent from "./types/TopupEsimTabComponent";
import {ITransactionObjectResponse} from "../../../../services/responses/dashboard.responses";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import {SIMLY_PARTNER_API_URL, STRIPE_PUBLIC_KEY} from "../../../../utils/config";

const stripePromise = loadStripe(`${STRIPE_PUBLIC_KEY}`);

type AbstractTabProp = {
    isTabOpen: boolean;
    setTabOpen: (state: boolean) => void;
    tabType: TabType;
    setTabType: (type: TabType) => void;
    transaction?: ITransactionObjectResponse;
    setLatestPurchaseId: (t: string) => void;
};

const AbstractTabComponent: React.FC<AbstractTabProp> = ({ isTabOpen, setTabOpen, tabType, setTabType, transaction, setLatestPurchaseId }: AbstractTabProp) => {
    return (
        <div className={`${isTabOpen ? 'w-[20rem] md:w-[30rem]' : 'w-0 2xl:w-0'} h-screen z-40 fixed top-0 right-0 bg-white rounded-l-[3rem] transition-all duration-200 `}>
            <div className="flex flex-col h-full w-full items-center">
                <div className="flex flex-row justify-between items-center w-10/12 pt-10">
                    <p className="text-2xl font-inter font-semibold text-[#333333]">{tabType}</p>
                    <button className={`bg-[#E71D36]/5 h-12 w-12 rounded-full ${isTabOpen ? '' : 'hidden'}`} onClick={() => setTabOpen(false)}>
                        <FontAwesomeIcon className={"text-[#E71D36]/80"} icon={faX} />
                    </button>
                </div>

                {isTabOpen ?
                    <div className="flex flex-col grow overflow-y-auto no-scrollbar h-full w-10/12">
                        {   tabType == TabType.GENERATE ? <GenerateTabComponent isTabOpen={isTabOpen} setTabOpen={setTabOpen} setLatestPurchaseId={setLatestPurchaseId} /> :
                            tabType == TabType.TOPUP ? <Elements stripe={stripePromise}><TopupBalanceTabComponent isTabOpen={isTabOpen} setTabOpen={setTabOpen}/></Elements> :
                                    tabType == TabType.ESIM_DETAILS ? <EsimTabComponent isTabOpen={isTabOpen} setTabOpen={setTabOpen} tabType={tabType} setTabType={setTabType} setLatestPurchaseId={setLatestPurchaseId} transaction={transaction!} /> :
                                        <TopupEsimTabComponent isTabOpen={isTabOpen} setTabOpen={setTabOpen} transaction={transaction!} setLatestPurchaseId={setLatestPurchaseId} />
                        }
                    </div> : <></>
                }

            </div>
        </div>
    );
}

export default AbstractTabComponent;
