import React, {useContext, useEffect, useState} from "react";
import {UserContext} from "../../../../../context/UserContext";
import {buildStyles, CircularProgressbar, CircularProgressbarWithChildren} from "react-circular-progressbar";
import {TabType} from "../AbstractTabTypes";
import {ITransactionObject} from "../../../../../services/responses/objects/ITransactionObject";
import {ITransactionObjectResponse} from "../../../../../services/responses/dashboard.responses";
import Lottie from "lottie-react";
import esimLoadingAnimation from "../../../../../animations/lottie_esim_loading.json";
import {RefundStatus, UsageResponse} from "../../../../../services/responses/esim.responses";
import {ESIMsService} from "../../../../../services/esims.service";
import {
    formatSimStatus,
    getSimStatusColor,
    getSimStatusColorSandwich,
    SimStatus
} from "../../../../../services/responses/enums/esim.status";
import {
    formatPlanStatus,
    getPlanStatusColor,
    getPlanStatusColorSandwiched
} from "../../../../../services/responses/enums/plan.status";
import {randomUUID} from "crypto";
import {toast} from "react-toastify";
import {generateRandomId} from "../../../../../utils/random";
import {UserDTO} from "../../../../../dto/UserDTO";

type EsimTabComponentProp = {
    isTabOpen: boolean;
    setTabOpen: (state: boolean) => void;
    tabType: TabType;
    setTabType: (type: TabType) => void;
    transaction: ITransactionObjectResponse;
    setLatestPurchaseId: (t: string) => void;
};

const EsimTabComponent: React.FC<EsimTabComponentProp> = ({ isTabOpen, setTabOpen, tabType, setTabType, transaction, setLatestPurchaseId }: EsimTabComponentProp) => {

    const {user, setUser} = useContext(UserContext);

    const [usage, setUsage] = useState<UsageResponse | undefined>(undefined);
    const [refunding, setRefunding] = useState<boolean>(false);

    useEffect(() => {
        ESIMsService.getUsage(transaction.esim.id).then(r => {
            setUsage(r.data);
        })
    }, [])

    const refund = async () => {
        if(refunding || transaction.transaction.refunded) {
            return;
        }

        setRefunding(true);

        const T = await ESIMsService.refund(transaction.transaction.uuid);

        if(T.data.status == RefundStatus.REFUNDED) {
            setLatestPurchaseId(generateRandomId())
            toast.success("Successfully refunded the required plan.")
            setTabOpen(false);

            setUser({
                ...user as UserDTO,
                balance: +Number(user!.balance + transaction.transaction.price).toFixed(3)
            })
        } else {
            toast.error(T.data.status + " - Could not refund the eSIM, contact Simly if you believe this is an error.");
        }

        setRefunding(false);
    }
    const closeTab = () => {
        setTabOpen(false);
    }

    const percentage = 10;

    return (
        <>
            <div className={"flex flex-col w-full drop-shadow-md bg-white rounded-3xl mt-[10%] px-6"}>
                <div className={"flex flex-row items-center justify-start gap-4 my-6"}>
                    <img src={transaction.country.countryImageURL} alt={transaction.country.isoCode} className={"w-10 h-10"}/>
                    <p className={"font-semibold text-xl"}>{transaction.country.name}</p>
                </div>

                {usage ?
                    <>
                        <div className={"flex flex-row w-full items-center justify-between"}>
                            <div className={"flex flex-col w-[50%] items-start justify-center"}>
                                <p className={"font-semibold text-4xl"}>{usage.sim.consumed}GB</p>
                                <p className={"font-semibold text-lg text-[#9D9D9D]"}>used from {usage.sim.size}GB</p>
                            </div>
                            <div className={"w-28 h-28 items-center justify-center"}>
                                <CircularProgressbarWithChildren
                                    value={usage.sim.consumedPercentage}
                                    text={`${usage.sim.consumedPercentage}%`}
                                    strokeWidth={10}
                                    styles={buildStyles({
                                        rotation: 0,
                                        strokeLinecap: 'round',
                                        textSize: '0px',
                                        pathTransitionDuration: 1.5,
                                        pathColor: `${usage.sim.status == SimStatus.ACTIVE ? getPlanStatusColor(usage.plan.status) :
                                            getSimStatusColor(usage.sim.status)}`,
                                        textColor: `${usage.sim.status == SimStatus.ACTIVE ? getPlanStatusColor(usage.plan.status) :
                                            getSimStatusColor(usage.sim.status)}`,
                                        trailColor: '#F7F7F7',
                                        backgroundColor: '#9D9D9D',
                                    })}
                                >
                                    <p className={`text-xl font-semibold`} style={{color: usage.sim.status == SimStatus.ACTIVE ? getPlanStatusColor(usage.plan.status) :
                                        getSimStatusColor(usage.sim.status)}}>{usage.sim.consumedPercentage}%</p>
                                </CircularProgressbarWithChildren >
                            </div>
                        </div>
                        <div className={"flex flex-col items-start justify-center mt-4 gap-1"}>
                            <div className={"flex flex-row items-center justify-between gap-2"}>
                                <div className={"w-4 h-4 rounded-full"} style={{backgroundColor: usage.sim.status == SimStatus.ACTIVE ? getPlanStatusColor(usage.plan.status) :
                                        getSimStatusColor(usage.sim.status)}}/>
                                <p className={"text-[#9D9D9D] font-semibold"}>{usage.sim.status == SimStatus.ACTIVE ? formatPlanStatus(usage.plan.status) :
                                    formatSimStatus(usage.sim.status)}</p>
                            </div>
                            <div className={"flex flex-row items-center justify-between gap-2"}>
                                <img alt="clock" src="/icons/clock.png" className={"w-4 h-4"}/>
                                <p className={"text-[#9D9D9D] font-semibold"}>Plan is still valid for {usage.plan.daysLeft} days</p>
                            </div>
                        </div>
                    </>:
                    <Lottie animationData={esimLoadingAnimation} loop={true} />
                }

                <div className={"flex flex-row items-center justify-center gap-6 my-9"}>
                    <div className={"flex items-center justify-center w-28 rounded-full h-12 bg-[#F8F8FA] cursor-pointer"}
                         onClick={() => setTabType(TabType.ESIM_TOPUP)}>
                        <p className={"text-[#222222] font-bold"}>Top Up</p>
                    </div>
                    <div className={`flex items-center justify-center w-28 rounded-full h-12 ${transaction.transaction.refunded ? 'bg-[#E71D36]/5 cursor-not-allowed' : 
                        refunding ? 'bg-[#F8F8FA]/50 cursor-not-allowed' : 'bg-[#F8F8FA] cursor-pointer'}`}
                        onClick={() => refund()}
                    >
                        <p className={"text-[#222222] font-bold"}>Refund</p>
                    </div>
                </div>
            </div>

            <div className={"flex flex-col h-24 w-full justify-between mt-7"}>
                <p className={"text-base font-inter font-medium text-[#030229]"}>Email</p>
                <div className={"flex items-center w-full h-14 rounded-xl bg-[#F7F7F8] focus:outline-none pl-5 font-semibold text-[#4C4B67]"}>
                    {transaction.user?.email ? transaction.user?.email : "-"}
                </div>
            </div>

            <div className={"flex flex-col h-24 w-full justify-between mt-7"}>
                <p className={"text-base font-inter font-medium text-[#030229]"}>Package Country</p>
                <div className={"flex items-center w-full h-14 rounded-xl bg-[#F7F7F8] focus:outline-none pl-5 font-semibold text-[#4C4B67]"}>
                    {transaction.country.name}
                </div>
            </div>

            <div className={"flex flex-col h-24 w-full justify-between mt-7"}>
                <p className={"text-base font-inter font-medium text-[#030229]"}>Package Type</p>
                <div className={"flex items-center w-full h-14 rounded-xl bg-[#F7F7F8] focus:outline-none pl-5 font-semibold text-[#4C4B67]"}>
                    {transaction.plan.name}
                </div>
            </div>

            <div className={"flex flex-col h-24 w-full justify-between mt-7"}>
                <p className={"text-base font-inter font-medium text-[#030229]"}>ICCID</p>
                <div className={"flex items-center w-full h-14 rounded-xl bg-[#F7F7F8] focus:outline-none pl-5 font-semibold text-[#4C4B67]"}>
                    {transaction.esim.iccid}
                </div>
            </div>

            <div className={"flex flex-col h-24 w-full justify-between mt-7"}>
                <p className={"text-base font-inter font-medium text-[#030229]"}>Price</p>
                <div className={"flex items-center w-full h-14 rounded-xl bg-[#F7F7F8] focus:outline-none pl-5 font-semibold text-[#4C4B67]"}>
                    {transaction.transaction.price}
                </div>
            </div>

            <div className={"flex flex-col h-24 w-full justify-between mt-7"}>
                <p className={"text-base font-inter font-medium text-[#030229]"}>Amount Earned</p>
                <div className={"flex items-center w-full h-14 rounded-xl bg-[#F7F7F8] focus:outline-none pl-5 font-semibold text-[#4C4B67]"}>
                    {transaction.transaction.received == 0 ? '-' : "$" + transaction.transaction.received}
                </div>
            </div>

            <div className={"flex flex-col h-20 w-full justify-between my-7"}>
            </div>

        </>
    );
}

export default EsimTabComponent;
