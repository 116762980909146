import React, {useContext, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faX} from "@fortawesome/free-solid-svg-icons";
import AsyncSelect from "react-select/async";
import {GroupBase, OptionsOrGroups, SingleValue} from "react-select";
import Select from "react-select";
import {confirmAlert} from "react-confirm-alert";
import {toast} from "react-toastify";
import {UserContext} from "../../../../../context/UserContext";
import PurchaseConfirmationAlert from "../../generate/alert/PurchaseConfirmationAlert";
import {CountryService} from "../../../../../services/country.service";
import {IBasicCountryObject} from "../../../../../services/responses/objects/ICountryObject";
import {CountryResponse, PlanResponse} from "../../../../../services/responses/country.responses";

type GenerateTabProp = {
    isTabOpen: boolean;
    setTabOpen: (state: boolean) => void;
    setLatestPurchaseId: (t: string) => void;
};

export interface SelectionOption {
    readonly value: string;
    readonly label: string;
    readonly url: string;
    readonly price: number;
}

const GenerateTabComponent: React.FC<GenerateTabProp> = ({ isTabOpen, setTabOpen, setLatestPurchaseId }: GenerateTabProp) => {

    const {user, setUser} = useContext(UserContext);

    const [email, setEmail] = useState<string>("");
    const [isoCode, setIsoCode] = useState<SelectionOption | null>(null);
    const [planId, setPlanId] = useState<SelectionOption | null>(null);
    const [quantity, setQuantity] = useState<number>(1);
    const [planOptions, setPlanOptions] = useState<SelectionOption[]>([]);
    const [defaultOptions, setDefaultOptions] = useState<SelectionOption[]>([]);
    const [isPlansLoading, setPlansLoading] = useState(false);
    const [country, setCountry] = useState<CountryResponse | undefined>(undefined);

    function isValidEmail(email: string) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return emailRegex.test(email)
    }

    const closeTab = () => {
        setTabOpen(false);
        setEmail("")
        setIsoCode(null)
        setPlanId(null)
        setPlanOptions([])
        setQuantity(1);
    }


    useEffect(() => {
        CountryService.searchCountries("").then((res) => {
            const options: SelectionOption[] = [...res.data.local, ...res.data.regional, ...res.data.global].map((item: IBasicCountryObject) => ({
                value: item.isoCode,
                label: item.name,
                url: item.countryImageURL
            }) as SelectionOption);

            setDefaultOptions(options);
            console.log(options);
        })
    }, []);

    async function loadOptions(inputValue: string, callback: (options: OptionsOrGroups<SelectionOption, GroupBase<SelectionOption>>) => void):
        Promise<OptionsOrGroups<SelectionOption, GroupBase<SelectionOption>>> {

        const options: SelectionOption[] = defaultOptions.filter(s => s.label.toLowerCase().startsWith(inputValue.toLowerCase()) || s.value.toLowerCase().startsWith(inputValue.toLowerCase()));

        callback(options);

        return options;
    }

    useEffect(() => {
        if(isoCode != null) {
            setPlansLoading(true);

            CountryService.getCountryByISO(isoCode.value).then((data) => {
                const options: SelectionOption[] = data.data.plans.map((item: PlanResponse) => ({
                    value: item.planId,
                    label: item.size + "GB - " + item.duration + " Days",
                    price: item.price
                }) as SelectionOption).sort((a: SelectionOption, b: SelectionOption) => a.price - b.price);

                console.log(options)

                setPlanOptions(options)
                setPlanId(options[0])
                setCountry(data.data);
            }).finally(() => setPlansLoading(false))
        }
    }, [isoCode])

    return (
        <>
            <div className={"flex flex-col h-24 w-full justify-between mt-14"}>
                <p className={"text-lg font-inter font-medium text-[#030229]"}>Customer Email</p>
                <input type="text" value={email}
                       onChange={(e) => setEmail(e.target.value)}
                       className="h-14 w-full rounded-xl bg-[#F7F7F8] focus:outline-none pl-5 text-[#4C4B67]"
                       placeholder="example@gmail.com" aria-label="Customer Email"></input>
            </div>

            <div className={"flex flex-col h-24 w-full justify-between mt-7"}>
                <p className={"text-lg font-inter font-medium text-[#030229]"}>Package Country</p>
                <AsyncSelect value={isoCode} cacheOptions loadOptions={loadOptions} defaultOptions={defaultOptions} onChange={(selected) => {
                    if (selected && (selected as SingleValue<SelectionOption>)!.value) {
                        setIsoCode(selected as SelectionOption);
                    } else {
                        setIsoCode(null);
                    }
                }} onMenuOpen={() => {
                    setIsoCode(null)
                    setPlanOptions([]);
                    setPlanId(null);
                }} />
            </div>

            <div className={"flex flex-col h-24 w-full justify-between mt-7"}>
                <p className={"text-lg font-inter font-medium text-[#030229]"}>Package Type</p>
                <Select
                    value={planId}
                    isDisabled={planOptions?.length == 0}
                    isLoading={isPlansLoading}
                    options={planOptions}
                    onChange={(selected) => {
                        if (selected && (selected as SingleValue<SelectionOption>)!.value) {
                            setPlanId(selected as SelectionOption);
                        } else {
                            setPlanId(null);
                        }
                    }}/>
            </div>

            <div className={"flex flex-col h-24 w-full justify-between mt-7"}>
                <p className={"text-lg font-inter font-medium text-[#030229]"}>Quantity</p>
                <input
                    type="number"
                    value={Number(quantity).toString()}
                    onChange={(e) => {
                        const inputValue = e.target.value;
                        const newValue = inputValue === '' ? 0 : parseFloat(inputValue);

                        setQuantity(newValue);
                    }}
                    className="h-14 w-full rounded-xl bg-[#F7F7F8] focus:outline-none pl-5 text-[#4C4B67]"
                    placeholder="1"
                    aria-label="eSIM Quantities"
                    min={1}
                    max={10}
                />
            </div>

            <button disabled={isoCode == undefined || planId == undefined} className={`flex flex-row w-full h-16 bg-[#5986FA] rounded-2xl mt-14 items-center justify-center ${(isoCode == undefined || planId == undefined) ? 'cursor-not-allowed' : 'cursor-pointer'}`} onClick={() => {

                if (email.length != 0 && !isValidEmail(email)) {
                    toast.error("The email provided is invalid!")
                    return;
                }

                if (isoCode == undefined || planId == undefined || country == undefined) {
                    toast.error("Choose a package before proceeding!")
                    return;
                }

                confirmAlert({
                    customUI: ({ onClose }) => {
                        return <PurchaseConfirmationAlert setLatestPurchaseId={setLatestPurchaseId} email={email} country={country} planId={planId!} quantity={quantity} onClose={onClose} closeTab={closeTab} setTabOpen={setTabOpen} user={user} setUser={setUser}/>
                    }
                });
            }}>
                <p className={"text-xl text-white font-inter font-medium"}>{planId != null ? planId.price * quantity : "N/A"}$ - Pay Now</p>
            </button>

            <div className="flex flex-col w-full h-12 justify-between items-start mt-10 shrink-0"/>
        </>
    );
}

export default GenerateTabComponent;
