import {SimStatus} from "./esim.status";

export enum PlanStatus {
    PENDING = "PENDING",
    ACTIVE = "ACTIVE",
    FULLY_USED = "FULLY_USED",
    EXPIRED = "EXPIRED"
}

export const formatPlanStatus = (status: PlanStatus): string => {
    return status
        .toLowerCase()
        .replace(/_/g, ' ')
        .replace(/\b\w/g, (match) => match.toUpperCase());
}

export const getPlanStatusColorSandwiched = (planStatus: PlanStatus, prefix: string, suffix: string) => {
    return prefix + getPlanStatusColor(planStatus) + suffix;
}

export const getPlanStatusColor = (planStatus: PlanStatus): string => {
    switch (planStatus) {
        case PlanStatus.ACTIVE:
            return "#33C759"
        case PlanStatus.PENDING:
            return "#F1A239"
        case PlanStatus.FULLY_USED:
            return "#FC4C02"
        case PlanStatus.EXPIRED:
            return "#FC4C02"
    }
}
