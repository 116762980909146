import {getAccessToken, SIMLY_PARTNER_API_URL} from "../utils/config";
import Cookies from "js-cookie";
import {BasicResponse} from "./responses/basic.response";
import {UserDTO} from "../dto/UserDTO";
import {ITransactionResponse} from "./responses/dashboard.responses";
import {LoginResponse} from "./responses/user.responses";

export class UserService {

    public static login = async (request: any): Promise<BasicResponse<LoginResponse>> => {
        const resp = await fetch( SIMLY_PARTNER_API_URL + "/login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({email: request.username.toLowerCase(), password: request.password})
        });

        return await resp.json() as BasicResponse<LoginResponse>;
    }

    public static getUser = async (): Promise<BasicResponse<UserDTO>> => {
        const resp = await fetch( SIMLY_PARTNER_API_URL + "/", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "x-simly-token": getAccessToken()!
            }
        });


        return await resp.json() as BasicResponse<UserDTO>;
    }

}
