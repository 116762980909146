import {randomBytes} from "crypto";

export const generateRandomId = () : string => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const codeLength = 20;

    let code = '';

    for (let i = 0; i < codeLength; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        code += characters[randomIndex];
    }

    code =
        code.substring(0, 5) + '-' +
        code.substring(5, 10) + '-' +
        code.substring(10, 15) + '-' +
        code.substring(15, 20);

    return code;
}
