import React from 'react';

interface CustomDropdownArrowProps {
    width: number;
    height: number;
}

const CustomDropdownArrow: React.FC<CustomDropdownArrowProps> = ({ width, height }) => (
    <svg width={width} height={height} viewBox="0 0 6 5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.7">
            <path opacity="0.7" d="M2.4345 4.81539C2.4055 4.78714 2.2815 4.68047 2.1795 4.5811C1.538 3.99854 0.488 2.47881 0.1675 1.68339C0.116 1.56259 0.007 1.25718 0 1.09401C0 0.937652 0.036 0.788602 0.109 0.646371C0.211 0.46907 0.3715 0.326839 0.561 0.248904C0.6925 0.198734 1.086 0.120799 1.093 0.120799C1.5235 0.0428641 2.223 0 2.996 0C3.7325 0 4.4035 0.0428641 4.8405 0.106673C4.8475 0.11398 5.3365 0.191914 5.504 0.277155C5.81 0.433512 6 0.738919 6 1.06576V1.09401C5.9925 1.30687 5.8025 1.75451 5.7955 1.75451C5.4745 2.50706 4.476 3.99172 3.8125 4.58841C3.8125 4.58841 3.642 4.75645 3.5355 4.82952C3.3825 4.9435 3.193 5 3.0035 5C2.792 5 2.595 4.93619 2.4345 4.81539Z" fill="#030229"/>
        </g>
    </svg>
);

export default CustomDropdownArrow;
