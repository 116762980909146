import React, {useContext, useEffect, useState} from "react";
import {UserContext} from "../../../../../context/UserContext";
import Select, {SingleValue} from "react-select";
import {SelectionOption} from "./GenerateTabComponent";
import CustomDropdownArrow from "../../../../global/CustomDropdownArrow";
import {ITransactionObjectResponse} from "../../../../../services/responses/dashboard.responses";
import {CountryService} from "../../../../../services/country.service";
import {PlanResponse} from "../../../../../services/responses/country.responses";
import {confirmAlert} from "react-confirm-alert";
import PurchaseConfirmationAlert from "../../generate/alert/PurchaseConfirmationAlert";

type TopupEsimTabComponentProp = {
    isTabOpen: boolean;
    setTabOpen: (state: boolean) => void;
    transaction: ITransactionObjectResponse;
    setLatestPurchaseId: (t: string) => void;
};

const TopupEsimTabComponent: React.FC<TopupEsimTabComponentProp> = ({ isTabOpen, setTabOpen, transaction, setLatestPurchaseId }: TopupEsimTabComponentProp) => {

    const {user, setUser} = useContext(UserContext);

    const closeTab = () => {
        setTabOpen(false);
    }

    const [planId, setPlanId] = useState<SelectionOption | null>(null);
    const [planOptions, setPlanOptions] = useState<SelectionOption[]>([]);
    const [isPlansLoading, setPlansLoading] = useState(false);

    useEffect(() => {
        if(transaction.country.isoCode != null) {
            setPlansLoading(true);

            CountryService.getCountryByISO(transaction.country.isoCode).then((data) => {
                const options: SelectionOption[] = data.data.plans.map((item: PlanResponse) => ({
                    value: item.planId,
                    label: item.size + "GB - " + item.duration + " Days",
                    price: item.price
                } as SelectionOption)).sort((a: SelectionOption, b: SelectionOption) => a.price - b.price);

                setPlanOptions(options)
                setPlanId(options[0])
            }).finally(() => setPlansLoading(false))
        }
    }, [transaction.country.isoCode])

    return (
        <>
            <div className={"flex flex-col h-24 w-full justify-between mt-7"}>
                <p className={"text-base font-inter font-medium text-[#030229]"}>Package Country</p>
                <div className={"flex items-center w-full h-14 rounded-xl bg-[#F7F7F8] focus:outline-none pl-5 font-inter font-medium text-[#030229]"}>
                    {transaction.country.name}
                </div>
            </div>
            <div className={"flex flex-col h-24 w-full justify-between mt-7"}>
                <p className={"text-lg font-inter font-medium text-[#030229]"}>Package Type</p>
                <div className="relative flex items-center w-full">
                    <select onChange={event => setPlanId(planOptions.find(s => s.value == event.target.value)!)}
                        className="appearance-none flex items-center w-full h-14 rounded-xl bg-[#F7F7F8] focus:outline-none pl-5 font-inter font-medium text-[#030229]">
                        {planOptions.map(option =>
                            <>
                                <option key={option.label} value={option.value}>{option.label}</option>
                            </>
                        )}
                    </select>

                    <div className="absolute inset-y-0 right-0 flex items-center pr-7 pointer-events-none">
                        <CustomDropdownArrow width={10} height={9} />
                    </div>
                </div>
            </div>

            <button className={`flex flex-row w-full h-16 bg-[#5986FA] rounded-2xl mt-14 items-center justify-center`} onClick={() => {
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return <PurchaseConfirmationAlert setLatestPurchaseId={setLatestPurchaseId} country={transaction.country} esimId={transaction.esim.id} planId={planId!} quantity={1} onClose={onClose} closeTab={closeTab} setTabOpen={setTabOpen} user={user} setUser={setUser}/>
                    }
                });
            }}>
                <p className={"text-xl text-white font-inter font-medium"}>{planId?.price}$ - Pay Now</p>
            </button>
        </>
    );
}

export default TopupEsimTabComponent;
